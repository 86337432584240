section.smart-contracts-development {
  padding: 126px 0 83px;

  .content-holder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  h1 {
    color: $black;
    font-size: 24px;
    line-height: 36px;
    font-weight: 900;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 140%;
      left: 43%;
      width: 51px;
      height: 3px;
      background-color: $theme-red;
    }
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #323232;
    padding-bottom: 20px;
  }

  @media (max-width: $md) {
    padding: 106px 0px 63px;
  }

  @media (max-width: $xs) {
    padding: 96px 0px 43px;
  }
}
