section.blockchain-infrastructure-introducion {
  padding: 150px 0px 103px;
  background-color: $theme-dark-blue;

  .flex-holder {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;

    .content-holder {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      color: $white;

      h1 {
        font-weight: 900;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
      }

      p {
        line-height: 27px;
        font-size: 18px;
      }

      .custom-btn {
        color: $white;
        max-width: 213px;
        max-height: 46px;
      }
    }

    .img {
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;

      img {
        max-width: 100%;
        margin: 0px;
      }

      @media (max-width: $md) {
        flex: 1 1 auto;
        padding-top: 30px;
      }
    }

    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  @media (max-width: $md) {
    padding: 120px 0px 73px;
  }
}