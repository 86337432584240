section.our-products-block {
  background-color: $theme-dark-blue;
  color: $white;
  padding: 106px 0 95px;

  @media (max-width: $lg) {
    padding: 60px 0;
  }

  @media (max-width: $md) {
    padding: 45px 0;
  }

  @media (max-width: $sm) {
    padding: 30px 0;
  }

  @media (max-width: $xs) {
    padding: 12px 0;
  }

  .tabs {
    display: flex;

    & > h6 {
      display: none;
      color: $theme-light-blue;
      margin-bottom: 7px;
    }

    @media (max-width: $md) {
      flex-direction: column;

      & > h6 {
        display: block;
      }
    }

    .left-col {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 401px;
      max-width: 401px;
      border-right: 1px solid rgba(255, 255, 255, 0.5);

      @media (max-width: $md) {
        min-width: 0;
        max-width: 100%;
        margin-bottom: 20px;
      }

      @media (max-width: $xs) {
        margin-bottom: 0;
      }

      .tab-title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 166px;
        border-radius: 10px 0px 0px 10px;
        transition: background-color $delay, border-color $delay;
        border-right: 3px solid rgba(0, 0, 0, 0);

        @media (max-width: $xs) {
          min-height: 97px;

          &:nth-child(1) {
            img {
              transform: translateX(-39px);
            }
          }

          &:nth-child(2) {
            img {
              transform: translateX(-35px);
              max-width: 174px;
            }
          }

          &:nth-child(3) {
            img {
              transform: translateX(-8px);
              max-width: 232px;
            }
          }
        }

        &:nth-child(3) {
          padding: 10px 0 34px 60px;

          @media (max-width: $xs) {
            padding: 0;
          }
        }

        &:hover, &.active {
          background: #384163;
          border-color: $theme-light-blue
        }

        img {
          display: block;

          @media (max-width: $xs) {
            max-width: 169px;
          }
        }
      }
    }

    .right-col {
      .tab-content {
        padding: 28px 5px 27px 100px;
        flex-direction: column;
        align-items: flex-start;
        display: none;

        @media (max-width: $md) {
          padding: 21px 0 20px 0;

          .text {
            padding-right: 40px;
          }
        }

        &.active {
          display: flex;
        }

        h6 {
          color: $theme-light-blue;
          margin-bottom: 8px;

          @media (max-width: $md) {
            display: none;
          }
        }

        h3 {
          font-weight: 700;
          margin-bottom: 30px;

          @media (max-width: $md) {
            margin-bottom: 27px;
          }

          @media (max-width: $sm) {
            margin-bottom: 23px;
          }

          @media (max-width: $xs) {
            margin-bottom: 17px;
          }
        }

        .custom-btn {
          margin-top: 32px;

          @media (max-width: $xs) {
            margin-top: 27px;
            width: 100%;
          }
        }
      }
    }
  }
}