footer.footer {
  background-color: $theme-secondary-blue;
  color: $white;
  padding: 64px 0 48px;

  @media (max-width: $lg) {
    padding: 50px 0 48px;
  }

  @media (max-width: $md) {
    padding: 40px 0 45px;
  }

  @media (max-width: $sm) {
    padding: 30px 0 40px;
  }

  @media (max-width: $xs) {
    padding: 21px 0 37px;
  }

  .flex-holder {
    display: flex;
    justify-content: center;

    @media (max-width: $lg) {
      flex-direction: column;
    }

    .navigation {
      width: 100%;

      @media (max-width: $lg) {
        width: 100%;
        max-width: 100%;
      }

      @media (max-width: $md) {
        display: flex;
        flex-direction: row;
        flex: auto;
        justify-content: space-around;
        align-items: center;
      }

      @media (max-width: $sm) {
        display: flex;
        flex-direction: row;
        flex: auto;
        justify-content: space-around;
        align-items: flex-start;
      }

      h6 {
        margin-bottom: 36px;

        @media (max-width: $md) {
          margin-bottom: 25px;
        }

        @media (max-width: $sm) {
          margin-bottom: 18px;
        }

        @media (max-width: $xs) {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 11px;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        justify-content: center;

        @media (max-width: $xs) {
          flex-direction: column;
          display: none;
        }

        &.mob {
          display: none;

          @media (max-width: $xs) {
            display: flex;
          }
        }

        li {
          list-style-type: none;
          width: auto;
          min-width: 30%;
          max-width: 33.33%;
          margin-bottom: 16px;
          text-align: center;

          @media (max-width: $lg) {
            width: 30%;
            margin-right: 30px !important;

            &:last-of-type {
              margin-right: 0;
            }
          }

          @media (max-width: $sm) {
            text-align: start;
          }

          @media (max-width: $xs) {
            margin: 0 0 7px;

            &:last-of-type {
              margin: 0;
            }

            &.bold {
              margin-top: 13px;

              > a {
                font-size: 16px;
                color: #00FFFF;
              }
            }
          }

          &:nth-child(3n+4), &:nth-child(1) {
            min-width: 25.6%;
          }

          &:nth-child(3n+5), &:nth-child(2) {
            margin-right: auto;
          }

          @media (min-width: $lg) {
            &:nth-child(3n+5), &:nth-child(2) {
              margin-right: 0;
            }
          }

          a {
            color: $white;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            white-space: nowrap;

            @media (max-width: $sm) {
              font-size: 14px;
            }

            @media (max-width: $xs) {
              font-size: 12px;
            }

            &:hover {
              color: #00FFFF;
            }
          }
        }
      }
    }
  }

  .copyright {
    margin-top: 36px;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #E4EAEC;
    text-align: center;
    letter-spacing: -1px;

    @media (max-width: $xs) {
      margin-top: 35px;
    }
  }

  .important-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #E4EAEC;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 65px;
    padding: 0 0 0 28px;

    @media (max-width: $xs) {
      flex-direction: column;
      margin-top: 45px;
      font-size: 12px;
      line-height: 150%;
      padding: 0;
      text-align: justify;
    }

    img {
      margin-right: 53px;

      @media (max-width: $xs) {
        margin: 0 0 30px;
        max-width: 11px;
      }
    }
  }
}