* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $poppins;
  font-weight: 400;
  font-size: 18px;
  color: $black;
  background-color: $white;
  margin: 0;

  @media (max-width: $sm) {
    font-size: 16px;
  }

  @media (max-width: $xs) {
    font-size: 14px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  box-sizing: content-box;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
}

a {
  text-decoration: none;
  transition: color $delay;
}

//chat-bot vidget
.amo-button-holder {
	right: 5%!important;
	bottom: 10%!important;
}