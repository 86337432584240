section.hero-common-section {
  padding: 137px 0 1px;

  @media (max-width: $lg) {
    padding: 137px 0 60px;
  }

  @media (max-width: $xs) {
    padding: 114px 0 58px;
  }

  .flex-holder {
    display: flex;

    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  .content-holder {
    width: 100%;
    max-width: 519px;
    min-width: 519px;
    padding: 70px 0 0 4px;

    @media (max-width: 1500px) {
      min-width: 0;
    }

    @media (max-width: $lg) {
      padding: 25px 0 0;
    }

    @media (max-width: $md) {
      max-width: 100%;
      padding: 0;
    }

    h5 {
      position: relative;
      font-weight: 900;
      font-size: 21px;
      line-height: 171%;
      padding-bottom: 22px;

      @media (max-width: $xs) {
        font-size: 24px;
        padding-bottom: 11px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 51px;
        height: 3px;
        background-color: $theme-red;
      }
    }

    .body-text {
      @media (max-width: $xs) {
        font-size: 12px;

        p {
          margin: 0 0 10px;

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  .img {
    width: 100%;
    max-width: 722px;
    min-width: 722px;
    transform: translateX(22px);

    @media (max-width: 1500px) {
      transform: none;
      min-width: 0;
    }

    @media (max-width: $md) {
      margin-top: 19px;
      width: calc(100% + 40px);
      margin-left: -20px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

body.page-portfolio {
  section.hero-common-section {
    padding: 137px 0 59px;
    background-image: url("../img/portfolio-hero-bg.png");
    background-repeat: no-repeat;
    background-size: 792px;
    background-position: 100% 77%;
    background-color: #f6f6f6;

    @media (max-width: $md) {
      background-position: 100% 61%;
    }

    @media (max-width: $sm) {
      padding: 120px 0 50px;
      background-size: 500px;
      background-position: 100% 40%;
    }

    @media (max-width: $xs) {
      padding: 101px 0 40px;
      background-size: 360px;
      background-position: 147px 51%;
    }

    .content-holder {
      max-width: 610px;
      padding-top: 0;

      h5 {
        padding-bottom: 19px;

        @media (max-width: $sm) {
          font-size: 20px;
          padding-bottom: 14px;
        }

        @media (max-width: $xs) {
          font-size: 18px;
          font-weight: 900;
          padding-bottom: 10px;
          margin-bottom: 15px;
        }
      }
    }
  }
}