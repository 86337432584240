section.get-started-banner {
  background-color: $theme-secondary-blue;
  margin-bottom: 40px;

  .flex-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 30px 20px;

    .content-holder {
      color: $white;
      flex: 1 1 60%;
      margin: 0px 50px 0px 0px;

      h5 {
        font-size: 24px;
        line-height: 36px;
        font-weight: 600;
      }

      p {
        font-size: 21px;
        line-height: 31.5px;
        padding: 0px 0px 15px 0px;

        span {
          color: $theme-light-blue;
        }
      }
      
      a {
        flex: 1 1 auto;
        max-width: 222px;
      }

      @media(max-width: $sm) {
        flex: 1 1 auto;
      }
    }

    .img {
      flex: 1 1 auto;

      img {
        max-width: 100%;
      }

      @media(max-width: $sm) {
        display: none;
      }
    }
  }
}