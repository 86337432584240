section.asset-tokenization-content {
  padding: 80px 0px 40px;

  .flex-holder {
    display: flex;
    flex-direction: column;

    .introducion {
      display: flex;

      .img {
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
        }

        @media (max-width: $md) {
          flex: 1 1 auto;
        }
      }

      .content-holder {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 18px;
        line-height: 27px;
        padding-left: 15px;

        h5 {
          color: #323232;
          font-weight: 600;
          margin: 0;
        }

        p {
          color: $theme-text-secondary;
        }
      }

      @media (max-width: $md) {
        flex-direction: column-reverse;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      padding: 40px 0px 40px;

      .card {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 30px;
        border: 2px #F5F6F8 solid;
        border-radius: 20px;
        background-color: #F5F6F8;
        padding: 45px;
    
        &:last-child {
          margin: 0;
        }
    
        .content-holder {
          display: flex;
          flex-direction: column;
    
          &:nth-child(odd) {
            flex: 0 0 33.3%;

            @media (max-width: $md) {
              flex: 1 1 auto;
            }
          }
    
          &:nth-child(even) {
            font-size: 18px;
            line-height: 27px;
            color: $theme-text-secondary;

            h6 {
              position: relative;
              padding-bottom: 25px;
              margin: 0;
            }

            .list-holder {
              display: flex;
              justify-content: flex-start;

              ul {
                flex: 1 0 50%;

                @media(max-width: $sm) {
                  margin: 0;
                }
              }

              @media(max-width: $sm) {
                flex-direction: column;
              }
            }

            ul {
              padding: 0px 0px 0px 25px;
              margin: 25px 0px;
    
              li {
                padding: 0px 0px 10px 0px;
    
                &:last-child {
                  padding-bottom: 0px;
                }
    
                &::marker {
                  list-style: circle;
                  color: $theme-red;
                  font-size: 150%;
                  padding: 0;
                }
              }
            }
          }
    
          h5 {
            color: $theme-red;
            font-weight: 600;
            font-size: 18px;
            line-height: 36px;
          }
    
          p {
            margin: 0;
            padding-bottom: 40px;
          }
        }

        @media (max-width: $md) {
          flex-direction: column;
          padding: 35px;
          margin-bottom: 25px;
        }
      }
    }

    .disclaimer {
      border: 2px #CCC7C9 solid;
      border-radius: 20px;
      display: flex;
      padding: 35px;

      .content-holder {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        font-size: 18px;
        line-height: 27px;

        a {
          display: flex;
          align-items: center;
          color: $theme-red;
        }
      }

      .img {
        flex: 0 0 33.3%;
        display: flex;
        align-items: center;

        img {
          max-width: 100%;
        }
        
        @media (max-width: $sm) {
          display: none;
        }
      }
    }
  }

  @media (max-width: $md) {
    padding: 40px 0px 30px;
  }
}