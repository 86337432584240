section.our-story {
  padding: 79px 0 114px;
  color: $white;
  background-color: #080734;

  @media (max-width: $lg) {
    padding: 70px 0 114px;
  }

  @media (max-width: $md) {
    padding: 60px 0 114px;
  }

  @media (max-width: $sm) {
    padding: 45px 0 114px;
  }

  @media (max-width: $xs) {
    padding: 35px 0 111px;
  }

  h3 {
    padding-left: 10px;
    margin-bottom: 52px;

    @media (max-width: $lg) {
      padding-left: 0;
    }

    @media (max-width: $sm) {
      margin-bottom: 45px;
    }

    @media (max-width: $xs) {
      margin-bottom: 38px;
    }
  }

  .timeline {
    padding-left: 10px;

    @media (max-width: $lg) {
      padding-left: 0;
    }

    .date {
      display: flex;
      position: relative;

      &:last-of-type {
        .description > div {
          padding-bottom: 0;

          &::after {
            display: none;
          }
        }
      }

      .year {
        font-size: 21px;
        font-weight: 500;
        line-height: 150%;
        position: absolute;
        left: 0;
        top: 0;

        @media (max-width: $md) {
          font-size: 20px;
        }

        @media (max-width: $sm) {
          font-size: 19px;
        }

        @media (max-width: $xs) {
          font-size: 18px;
        }
      }

      .description {
        font-weight: 400;
        font-size: 17px;
        line-height: 170%;
        width: 100%;
        margin-left: 160px;

        @media (max-width: $md) {
          font-size: 15px;
        }

        @media (max-width: $sm) {
          font-size: 14px;
        }

        @media (max-width: $xs) {
          font-size: 12px;
          margin-left: 77px;
        }

        &.double > div:nth-child(1)::after {
          background-color: $white;
        }

        & > div {
          padding-bottom: 35px;
          position: relative;

          @media (max-width: $xs) {
            &:not(:last-of-type) {
              padding-bottom: 32px;
            }

            padding-bottom: 49px;
            padding-right: 5px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 12px;
            left: -62px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $white;

            @media (max-width: $xs) {
              display: none;
            }
          }

          &::after {
            content: '';
            position: absolute;
            top: 12px;
            left: -58px;
            width: 1px;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.22);

            @media (max-width: $xs) {
              display: none;
            }
          }
        }
      }
    }
  }
}