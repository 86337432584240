section.contact-us-block {
  padding: 44px 0;
  background-color: $theme-dark-blue;

  @media (max-width: $sm) {
    padding: 40px 0;
  }

  @media (max-width: $xs) {
    padding: 36px 0 38px;
  }

  .flex-holder {
    display: flex;
    justify-content: space-between;

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  .left-col {
    padding: 42px 0 0 0;
    width: 100%;
    max-width: 500px;
    margin-right: 40px;

    @media (max-width: $md) {
      padding-top: 0;
    }

    @media (max-width: $sm) {
      padding: 0;
      margin: 0px 20px 40px 0px;
    }

    h3 {
      color: $white;
      font-size: 32px;
      line-height: 66px;
      position: relative;
      padding-top: 25px;
      margin-bottom: 20px;

      @media (max-width: $md) {
        font-size: 24px;
        line-height: 45px;
        margin-bottom: 18px;
      }

      @media (max-width: $sm) {
        font-size: 20px;
        line-height: 40px;
        padding-top: 24px;
        margin-bottom: 15px;
      }

      @media (max-width: $xs) {
        line-height: 36px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 51px;
        height: 3px;
        background-color: $theme-red;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $white;
      margin-bottom: 25px;

      a {
        display: flex;
        align-items: center;
        color: #00FFFF;
        margin-bottom: 31px;

        @media (max-width: $lg) {
          margin-bottom: 25px;
        }

        @media (max-width: $md) {
          font-size: 18px;
          margin-bottom: 20px;
        }

        @media (max-width: $sm) {
          margin-bottom: 15px;
        }

        &:hover {
          color: $theme-red;
        }

        svg {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }

      div {
        display: flex;
        align-items: center;

        svg {
          width: 40px;
          height: 40px;
          margin-right: 11px;

          @media (max-width: $sm) {
            width: 48px;
            height: 48px;
          }
        }
      }
    }

    .socials {
      h6 {
        font-size: 24px;
        margin-bottom: 11px;
        color: $white;

        @media (max-width: $md) {
          font-size: 18px;
        }

        @media (max-width: $xs) {
          margin-bottom: 14px;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        li {
          list-style-type: none;
          margin-right: 37px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .right-col {
    padding: 42px 0 0 0;
    width: 100%;
    max-width: 501px;
    margin-left: 50px;

    @media (max-width: $lg) {
      margin-left: 0;
    }

    @media (max-width: $md) {
      padding: 0px;
    }

    .subscribe-form {
      margin-bottom: 22px;

      @media (max-width: $xs) {
        margin-bottom: 18px;
      }

      h6 {
        font-size: 32px;
        padding: 25px 0px 5px 0px;
        color: $white;
        position: relative;

        @media (max-width: $md) {
          font-size: 24px;
        }

        @media (max-width: $xs) {
          font-size: 20px;
          margin-bottom: 19px;
        }
        
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 51px;
          height: 3px;
          background-color: $theme-red;
        }
      }

      form {
        display: flex;
        align-items: center;

        @media (max-width: $xs) {
          flex-direction: column;
        }

        input[type="email"] {
          width: 100%;
          max-width: 322px;
          margin-right: 16px;
          font-family: $poppins;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          color: $white;
          border: 2px solid #5E6C9D;
          border-radius: 18px;
          transition: border-color $delay;
          height: 48px;
          background: none;
          padding: 0 14px;
          outline: none;

          @media (max-width: $xs) {
            font-size: 16px;
            border-radius: 10px;
            margin-right: 0;
            max-width: 100%;
          }

          &::placeholder {
            color: #E4EAEC;
          }

          &:hover,
          &:focus {
            border-color: $white;
          }
        }

        button[type="submit"] {
          padding: 14px 5px;
          width: 100%;
          max-width: 163px;
          min-width: 0;
          border-radius: 18px;

          @media (max-width: $xs) {
            border-radius: 10px;
            width: 100%;
            max-width: 100%;
            margin-top: 13px;
            padding: 15px 5px;
          }
        }
      }

      .form-description {
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        color: #939393;
        margin-top: 22px;
        width: 101%;

        @media (max-width: $xs) {
          padding-right: 35px;
        }
      }
    }

  }
}