button {
  appearance: none;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-family: $poppins;
  font-weight: 500;
  background: none;
}

input[type="submit"] {
  cursor: pointer;
}

.custom-btn {
  display: inline-block;

  &.red {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: $theme-red;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: $white;
    box-shadow: 0px 4px 27px rgba(237, 28, 36, 0.3);
    border-radius: 10px;
    padding: 20px 22px 20px 26px;
    min-width: 222px;
    transition: box-shadow $delay;

    @media (max-width: $sm) {
      font-size: 14px;
    }

    @media (max-width: $xs) {
      font-size: 12px;
      padding: 15px 20px;
      border-radius: 7px;
    }

    &:hover {
      box-shadow: 0px 4px 27px rgba(237, 28, 36, 0.7);
    }

    svg {
      display: block;
      width: 18px;
      height: 12px;
      margin-left: 12px;
    }
  }

  &.arrow {
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 8px 16px;
    min-height: 46px;
    font-size: 18px;
    line-height: 150%;
    color: $theme-blue;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    transition: border-color $delay;

    @media (max-width: $md) {
      font-size: 16px;
    }

    @media (max-width: $sm) {
      font-size: 14px;
    }

    @media (max-width: $xs) {
      font-size: 12px;
      min-height: 0;
      padding: 7px 13px;
    }

    &:hover {
      border-color: $theme-blue;
    }

    svg {
      margin-left: 6px;
      width: 24px;
      height: 24px;

      @media (max-width: $xs) {
        margin-left: 21px;
      }
    }
  }

  &.arrow-in-circle {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 16px 22px;
    font-size: 21px;
    font-weight: 500;
    line-height: 150%;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 301px;
    transition: border-color $delay;

    @media (max-width: $md) {
      font-size: 20px;
      padding: 14px 22px;
    }

    @media (max-width: $sm) {
      font-size: 19px;
      padding: 12px 15px;
    }

    @media (max-width: $xs) {
      font-size: 18px;
      min-width: 0;
      padding: 10px 15px;
      justify-content: center;
    }

    &:hover {
      border-color: $theme-light-blue;
    }

    svg {
      width: 34px;
      height: 34px;
      margin-left: 20px;

      @media (max-width: $md) {
        width: 31px;
        height: 31px;
      }

      @media (max-width: $sm) {
        width: 28px;
        height: 28px;
      }

      @media (max-width: $xs) {
        width: 25px;
        height: 25px;
        margin-left: 13px;
      }
    }
  }
}