section.options {
  padding: 70px 0px;

  .container {
    padding: 0 40px;

    @media(max-width: $sm) {
      padding: 0 20px;
    }
  }

  .card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    border: 2px #F5F6F8 solid;
    border-radius: 20px;
    background-color: #F5F6F8;
    padding: 45px;

    &:last-child {
      margin: 0;
    }

    .content-holder {
      display: flex;
      flex-direction: column;

      &:nth-child(odd) {
        flex: 0 0 33.3%;

        @media (max-width: $md) {
          flex: 1 1 auto;
        }
      }

      &:nth-child(even) {
        color: #323232;
        font-size: 18px;
        line-height: 27px;
      }

      h5 {
        color: $theme-red;
        font-weight: 600;
        font-size: 18px;
        line-height: 36px;
      }

      p {
        margin: 0;
        padding-bottom: 25px;

        &.lined {
          position: relative;

          &:first-of-type::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1.5px;
            background-color: #D9E2ED;
          }
        }
      }

      .options {
        ul {
          padding: 0px 0px 0px 25px;
          margin: 0px;
          color: $theme-text-secondary;

          li {
            padding: 0px 0px 25px 0px;

            &:last-child {
              padding-bottom: 0px;
            }

            &::marker {
              color: $theme-red;
              font-size: 150%;
              padding: 0;
            }
          }
        }
      }
    }

    @media (max-width: $md) {
      flex-direction: column;
      padding: 35px;
      margin-bottom: 45px;
    }

    @media(max-width: $sm) {
      padding: 25px;
    }
  }
}