.large-body-text {
  font-size: 21px;
  line-height: 150%;

  @media (max-width: $md) {
    font-size: 18px;
  }

  @media (max-width: $sm) {
    font-size: 16px;
  }

  @media (max-width: $xs) {
    font-size: 12px;
  }
}

.body-text {
  font-size: 18px;
  line-height: 150%;

  @media (max-width: $sm) {
    font-size: 16px;
  }

  @media (max-width: $xs) {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px 0;
  font-family: $poppins;
  font-weight: 600;
}

h1 {
  font-size: 48px;
  line-height: 66px;

  @media (max-width: $lg) {
    font-size: 42px;
    line-height: 52px;
  }

  @media (max-width: $md) {
    font-size: 35px;
    line-height: 45px;
  }

  @media (max-width: $sm) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: $xs) {
    font-size: 24px;
    line-height: 36px;
  }
}

h2 {
  font-size: 40px;
  line-height: 150%;

  @media (max-width: $md) {
    font-size: 35px;
  }

  @media (max-width: $sm) {
    font-size: 30px;
  }

  @media (max-width: $xs) {
    font-size: 24px;
  }
}

h3 {
  font-size: 32px;
  line-height: 112%;

  @media (max-width: $md) {
    font-size: 30px;
  }

  @media (max-width: $sm) {
    font-size: 26px;
  }

  @media (max-width: $xs) {
    font-size: 24px;
  }
}

h4 {
  font-size: 26px;
  line-height: 150%;

  @media (max-width: $md) {
    font-size: 24px;
  }

  @media (max-width: $sm) {
    font-size: 22px;
  }

  @media (max-width: $xs) {
    font-size: 18px;
  }
}

h5 {
  font-size: 22px;
  line-height: 164%;

  @media (max-width: $sm) {
    font-size: 20px;
  }

  @media (max-width: $xs) {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
  line-height: 200%;
}