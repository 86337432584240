section.blockchain-development {
  padding: 40px 0 40px;
  line-height: 27px;

  h4 {
    font-weight: 600;
    font-size: 18px;
    color: #323232;
    text-align: start;
    padding: 40px 20px 40px;
    margin: 0;
  }

  .flex-holder {
    display: flex;
    flex-direction: column;

    .card {
      display: flex;
      border: 2px #F5F6F8 solid;
      border-radius: 20px;
      background-color: #F5F6F8;
      margin-bottom: 40px;
      font-size: 18px;
      padding: 45px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .content-holder {
        &:nth-child(odd) {
          flex: 0 0 33.3%;

          h5 {
            margin: 0;
            color: $theme-red;
          }

          @media (max-width: $md) {
            flex: 1 1 auto;
          }
        }

        &:nth-child(even) {
          color: $theme-text-secondary;

          p {
            color: #323232;
            margin: 0;
            position: relative;
            max-width: 800px;
            padding: 0px 0px 25px 0px;

            &::after {
              content: '';
              position: absolute;
              bottom: 0%;
              left: 0;
              display: block;
              width: 100%;
              height: 1.5px;
              background-color: #D9E2ED;
            }
          }

          ul {
            margin: 0;
            padding: 20px 0px 0px 20px;

            li {
              color: $theme-text-secondary;
              padding-bottom: 7px;

              &:last-of-type {
                padding-bottom: 0;
              }

              &::marker {
                list-style: circle;
                color: $theme-red;
                font-size: 150%;
                padding: 0;
              }

              a {
                color: $theme-red;
              }
            }
          }
        }
      }

      @media (max-width: $md) {
        flex-wrap: wrap;
        padding: 35px;
      }
    }
  }
}