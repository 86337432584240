section.portfolio {
  padding: 66px 0 83px;

  @media (max-width: $lg) {
    padding: 56px 0 83px;
  }

  @media (max-width: $md) {
    padding: 50px 0 83px;
  }

  @media (max-width: $sm) {
    padding: 40px 0 83px;
  }

  @media (max-width: $xs) {
    padding: 34px 0 87px;
  }

  .flex-holder {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @media (max-width: $xs) {
      margin: 0;
    }

    .card {
      width: calc(33.33% - 30px);
      display: flex;
      flex-direction: column;
      border: 1px solid #E2E2E2;
      border-radius: 10px;
      background-color: $white;
      margin: 0 15px 29px;
      box-shadow: 0px 2px 29px rgba(0, 0, 0, 0);
      transition: box-shadow $delay;

      @media (max-width: $md) {
        width: calc(50% - 30px);
      }

      @media (max-width: $xs) {
			  width: calc(50% - 20px);
        margin: 0 10px 19px;
      }

      &:hover {
        box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.13);
      }

      .thumb {
        margin: 20px;

        img {
          display: block;
          max-height: 60px;
          width: 100%;
          border-radius: 10px 10px 0px 0px;
        }

        @media (max-width: $xs) {
          margin: 15px;
        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px 19px 26px;
        border-radius: 10px;

        @media (max-width: $xs) {
          padding: 20px 19px 15px;
        }

        .arrow {
          width: 47px;
          height: 47px;
          border-radius: 50%;
          position: absolute;
          top: -23px;
          right: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.13);
          transition: box-shadow $delay;

          @media (max-width: $xs) {
            width: 30px;
            height: 30px;
            top: -20px;
            right: 18px;
          }

          &:hover {
            box-shadow: 0px 2px 29px rgba(0, 0, 0, 0.25);
          }

          svg {
            width: 24px;
          }
        }

        h6 {
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          margin-bottom: 15px;
          color: #323232;
          width: 100%;
          max-width: 250px;

          @media (max-width: $xs) {
            font-size: 16px;
            max-width: 200px;
            margin-bottom: 11px;
          }
        }

        .description {
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
          color: #323232;
          opacity: 0.7;
        }
      }
    }
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #323232;
    margin-top: 35px;

    @media (max-width: $md) {
      margin-top: 20px;
    }

    @media (max-width: $sm) {
      margin-top: 15px;
    }

    @media (max-width: $xs) {
      margin-top: 5px;
    }

    svg {
      width: 39px;
      height: 39px;
      margin-bottom: 12px;
      animation: spin 0.4s linear infinite;

      @keyframes spin {
        100% {
          transform:rotate(360deg);
        }
      }
    }
  }
}