section.our-team {
  padding: 62px 0 40px;

  @media (max-width: $md) {
    padding: 55px 0 50px;
  }

  @media (max-width: $sm) {
    padding: 50px 0 40px;
  }

  @media (max-width: $xs) {
    padding: 43px 0 33px;
  }

  h3 {
    margin-bottom: 23px;

    @media (max-width: $xs) {
      font-size: 26px;
      margin-bottom: 28px;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 21px;
    line-height: 170%;
    color: #323232;
    margin-left: 9px;

    @media (max-width: $lg) {
      margin-left: 0;
      font-size: 19px;
    }

    @media (max-width: $md) {
      font-size: 18px;
    }

    @media (max-width: $sm) {
      font-size: 17px;
    }

    @media (max-width: $xs) {
      font-size: 16px;
      letter-spacing: -0.6px;
    }
  }

  .flex-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 37px -15px 0;

    @media (max-width: $sm) {
      margin: 33px -15px 0;
    }

    @media (max-width: $xs) {
      margin: 28px 0 0;
		  flex-direction: column;
    }

    .teammate {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 1 50%;
      padding: 0 15px;
      margin-bottom: 40px;

      @media (max-width: $md) {
        width: 50%;
      }

      @media (max-width: $sm) {
        margin-bottom: 36px;
      }

      @media (max-width: $xs) {
        width: 100%;
        padding: 0px 10px;
        margin-bottom: 31px;
      }

      .img {
        display: flex;
        justify-content: center;
        margin-bottom: -61px;
        position: relative;
        z-index: 1;

        img {
          display: block;
          width: 100%;
          max-width: 228px;
          border-radius: 50%;
        }
      }

      .card-content {
        background: #F6F6F6;
        border-radius: 20px;
        padding: 76px 23px 29px;
		  height: 100%;

        @media (max-width: $xs) {
          padding: 103px 18px 29px 20px;
        }

        .name {
          font-weight: 500;
          font-size: 23px;
          line-height: 150%;
          text-align: center;

          @media (max-width: $xs) {
            margin-bottom: 5px;
          }
        }

        .role {
          font-weight: 400;
          font-size: 18px;
          line-height: 170%;
          opacity: 0.4;
          text-align: center;
        }

        .description {
          font-weight: 400;
          font-size: 18px;
          line-height: 170%;
          opacity: 0.8;
          margin-top: 16px;

          @media (max-width: $xs) {
            margin-top: 35px;
            letter-spacing: -0.5px;
          }
        }
      }
    }
  }
}