section.hero-home-section {
  background: linear-gradient(180deg, #2E3652 0%, #0B1427 100%);
  color: $white;
  overflow: hidden;

  .flex-holder {
    display: flex;
    position: relative;
    padding: 152px 0 120px;

    @media (max-width: $md) {
      flex-direction: column;
      padding: 152px 0 40px;
    }

    @media (max-width: $sm) {
      padding: 120px 0 30px;
    }

    @media (max-width: $xs) {
      padding: 87px 0 30px;
    }
  }

  .content-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    z-index: 1;

    h1 {
      margin: 0;
      width: 100%;
      max-width: 700px;
      padding-bottom: 35px;
      margin-bottom: 35px;
      position: relative;

      @media (max-width: $md) {
        padding-bottom: 0;
      }

      @media (max-width: $sm) {
        margin-bottom: 30px;
      }

      @media (max-width: $xs) {
        margin-bottom: 26px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 51px;
        height: 3px;
        background-color: $theme-red;

        @media (max-width: $md) {
          display: none;
        }
      }
    }

    .text {
      width: 100%;
      max-width: 620px;

      p {
        margin: 0 0 35px;

        @media (max-width: $md) {
          margin: 0 0 30px;
        }

        @media (max-width: $sm) {
          margin: 0 0 25px;
        }

        @media (max-width: $xs) {
          margin: 0 0 18px;
        }

        &:last-of-type {
          margin: 0;
        }

        strong {
          font-weight: 700;
        }
      }
    }

    .custom-btn {
      margin-top: 27px;

      @media (max-width: $sm) {
        margin-top: 20px;
      }

      @media (max-width: $xs) {
        margin-top: 15px;
        width: 100%;
      }
    }
  }

  .img {
    min-width: 806px;
    position: absolute;
    right: -267px;
    bottom: -5px;

    @media (max-width: $md) {
      position: static;
      min-width: 0;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-top: 31px;
		display: none;
    }

    img {
      display: block;
      width: 100%;

      @media (max-width: $xs) {
        display: none;
      }

      &.mob {
        display: none;

        @media (max-width: $xs) {
          display: none;
        }
      }
    }
  }
}