section.asset-tokenization-introducion {
  background-color: $theme-dark-blue;
  padding: 150px 0px 70px;

  .flex-holder {
    display: flex;
    justify-content: space-between;

    .content-holder {
      color: $white;
      flex: 1 1 50%;
      padding-right: 10px;

      h1 {
        font-weight: 900;
        font-size: 24px;
        line-height: 36px;
      }

      p {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;

        &:last-of-type {
          padding-top: 15px;
        }
      }

      .custom-btn {
        color: $white;
        max-width: 213px;
        max-height: 46px;
      }

      @media (max-width: $md) {
        flex: 1 1 auto;
      }
    }

    .img {
      flex: 0 0 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;

      img {
        max-width: 100%;
      }

      @media (max-width: $md) {
        flex: 1 1 auto;
        padding: 30px 0px;
      }
    }

    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  @media (max-width: $md) {
    padding: 100px 0px 30px;
  }
}