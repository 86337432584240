section.stats {
  .flex-holder {
    display: flex;
    padding: 0px 40px 30px;

    @media (max-width: $md) {
      flex-direction: column;
    }
  }

  .content-holder {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;

    &:nth-child(1) {
      h5 {
        margin-bottom: 25px;
      }

      p {
        margin: 0;
        padding-bottom: 25px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    &:nth-child(2) {
      padding-left: 90px;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0px -8px;

      .stats-card {
        flex: 0 1 50%;
        margin: 0px 0px 50px 0px;
        padding: 0px 8px;

        h5 {
          color: $theme-red;
          line-height: 32px;
          font-weight: 700;
          font-size: 42px;
        }

        @media (max-width: $md) {
          padding: 0;
          margin-bottom: 25px;
        }
      }

      @media (max-width: $md) {
        padding: 40px 0px 0px 0px;
      }

      @media (max-width: $xs) {
        flex: 1 1 auto;
        flex-direction: column;
        flex-wrap: nowrap;
      }
    }

    p {
      padding: 0;
      color: $theme-text-secondary;
      line-height: 27px;
      font-size: 18px;
    }
  }
}