section.options-banner {
  .banner-body {
    margin: 0px 20px;
    padding: 60px 70px;
    background-color: $theme-dark-blue;
    border-radius: 20px;
    color: $white;
    position: relative;

    h6 {
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      margin-bottom: 37px;
      text-align: start;
    }

    .flex-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      a {
        border-radius: 18px;
      }
  
      .custom-btn {
        border-radius: 10px;
  
        &.arrow {
          color: $white;
        }
      }
    }
  
    .content-holder {
      display: flex;
      justify-content: space-between;
  
      ul {
        flex: 1 1 50%;
        margin-right: 40px;
        padding-left: 20px;

        &:last-of-type {
          margin-right: 0;
        }
  
        li {
          font-size: 18px;
          line-height: 27px;
          padding-bottom: 30px;

          &::marker {
            color: $theme-red;
            font-size: 150%;
            padding: 0;
          }
        }

        @media(max-width: $sm) {
          margin: 0;
        }
      }

      @media(max-width: $sm) {
        flex-direction: column;
      }
    }

    .img {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -19.5%;
      right: 0;

      @media(max-width: $sm) {
        display: none;
      }
    }

    @media(max-width: $sm) {
      padding: 40px 30px;
    }
  }
}