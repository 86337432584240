section.featured-projects {
  padding: 141px 0 90px;

  @media (max-width: $lg) {
    padding: 120px 0 90px;
  }

  @media (max-width: $md) {
    padding: 90px 0;
  }

  @media (max-width: $sm) {
    padding: 70px 0;
  }

  @media (max-width: $xs) {
    padding: 50px 0 25px;
  }

  .title-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    @media (max-width: $sm) {
      margin-bottom: 8px;
    }

    @media (max-width: $xs) {
      margin-bottom: 5px;
    }

    h6 {
      color: $theme-red;
      margin: 0;

      @media (max-width: $sm) {
        font-size: 16px;
      }

      @media (max-width: $xs) {
        font-size: 14px;
        font-weight: 600;
        line-height: 257%;
      }
    }

    a {
      color: $theme-blue;
      margin-right: 12px;

      @media (max-width: $sm) {
        margin-right: 0;
      }

      &:hover {
        color: $theme-red;
      }
    }
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;

    @media (max-width: $sm) {
      margin: 0 -6px;
    }

    & > a {
      width: 25%;
      max-width: 268px;
      margin: 0 16px 35px;
      background-color: #F7F7F7;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 26px 22px;
      transition: border-color $delay;
      border: 2px solid #F7F7F7;

      @media (max-width: $lg) {
        width: calc(33.33% - 32px);
        max-width: 100%;
      }

      @media (max-width: $sm) {
        width: calc(50% - 10px);
        margin: 0 5px 10px;
      }

      @media (max-width: $xs) {
        padding: 14px 13px;
      }

      &:hover {
        border-color: $theme-red;
      }

      img {
        display: block;
        margin-bottom: 16px;

        @media (max-width: $sm) {
          margin-bottom: 12px;
        }

        @media (max-width: $xs) {
          max-width: 120px;
          margin-bottom: 8px;
        }
      }

      .description {
        position: relative;
        color: $theme-primary-blue;
        padding-top: 22px;

        @media (max-width: $md) {
          padding-top: 18px;
        }

        @media (max-width: $sm) {
          font-size: 14px;
          padding-top: 16px;
        }

        @media (max-width: $xs) {
          font-size: 12px;
          padding-top: 14px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 2px;
          width: 21px;
          background-color: $theme-red;
        }
      }
    }
  }
}