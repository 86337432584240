section.latest-posts {
  padding: 74px 0 161px;

  @media (max-width: $lg) {
    padding: 60px 0 130px;
  }

  @media (max-width: $md) {
    padding: 45px 0 90px;
  }

  @media (max-width: $sm) {
    padding: 30px 0 50px;
  }

  @media (max-width: $xs) {
    padding: 12px 0 30px;
  }

  .title-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;

    @media (max-width: $lg) {
      margin-bottom: 10px;
    }

    h6 {
      color: $theme-red;
      margin: 0;

      @media (max-width: $sm) {
        font-size: 16px;
      }

      @media (max-width: $xs) {
        font-size: 14px;
        font-weight: 600;
        line-height: 257%;
      }
    }

    a {
      color: $theme-blue;
      margin-right: 3px;

      @media (max-width: $lg) {
        margin-right: 0;
      }

      &:hover {
        color: $theme-red;
      }
    }
  }

  .posts-holder {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;

    @media (max-width: $sm) {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 20px;
    }

    @media (max-width: $xs) {
      margin: 0 -10px;
    }

    .post-card {
      width: 33.33%;
      padding: 0 15px;
      margin-bottom: 40px;

      @media (max-width: $sm) {
        width: 100%;
        padding: 0;
        margin: 0 15px;
        min-width: 275px;
        max-width: 275px;
      }

      @media (max-width: $xs) {
        margin: 0 10px;
      }

      &:hover h5 {
        color: $theme-red;
      }

      .thumb {
        margin-bottom: 10px;

        @media (max-width: $md) {
          margin-bottom: 8px;
        }

        @media (max-width: $sm) {
          margin-bottom: 6px;
        }

        @media (max-width: $xs) {
          margin-bottom: 4px;
        }
      }

      .content-holder {
        padding-right: 15px;

        @media (max-width: $xs) {
          padding-right: 0;
        }
      }

      .date {
        color: #BBC3E2;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 5px;

        @media (max-width: $xs) {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }

      h5 {
        color: #323232;
        font-weight: 700;
        font-size: 21px;
        line-height: 171%;
        margin: 0;
        transition: color $delay;

        @media (max-width: $md) {
          font-size: 20px;
        }

        @media (max-width: $sm) {
          font-size: 19px;
        }

        @media (max-width: $xs) {
          font-size: 18px;
        }
      }

      .excerpt {
        color: $theme-text-secondary;

        @media (max-width: $md) {
          font-size: 16px;
        }

        @media (max-width: $sm) {
          font-size: 14px;
        }

        @media (max-width: $xs) {
          font-size: 12px;
          line-height: 150%;
          margin-top: 2px;
        }
      }
    }
  }
}