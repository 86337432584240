section.our-services-section {
  padding: 120px 0 148px;

  @media (max-width: $lg) {
    padding: 110px 0;
  }

  @media (max-width: $md) {
    padding: 90px 0;
  }

  @media (max-width: $sm) {
    padding: 70px 0;
  }

  @media (max-width: $xs) {
    padding: 59px 0 67px;
  }

  h6 {
    color: $theme-red;
    margin-bottom: 13px;

    @media (max-width: $sm) {
      display: none;
    }
  }

  .services {
    .service-card {
      border: 2px solid #D6D6D6;
      border-radius: 10px;
      padding: 70px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      min-height: 469px;

      @media (max-width: $sm) {
        border: 0;
        padding: 0;
        min-height: 0;
        margin-bottom: 40px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:nth-child(even) {
        .flex-holder {
          flex-direction: row-reverse;

          .img {
            justify-content: flex-end;
          }
        }
      }

      .flex-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: $md) {
          flex-direction: column !important;
        }
      }

      .img {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;

        @media (max-width: $xs) {
          min-height: 203px;
        }

        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 351px;
          max-height: 323px;

          @media (max-width: $xs) {
            max-width: 244px;
            max-height: 203px;
          }
        }
      }

      .content-holder {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h5 {
          font-weight: 700;
          margin-bottom: 15px;

          @media (max-width: $sm) {
            margin-bottom: 12px;
          }

          @media (max-width: $xs) {
            margin-bottom: 8px;
          }
        }

        .text {
          color: $text-gray;

          @media (max-width: $sm) {
            padding-right: 20px;
          }
        }

        .button-holder {
          display: flex;
          justify-content: space-between;
          margin-top: 22px;

          .custom-btn {
            flex: 0 1 auto;
            margin-right: 10px;

            &:last-of-type {
              margin-right: 0px;
            }
          }

          @media (max-width: $xs) {
            margin-top: 18px;
          }
        }
      }
    }
  }
}