header.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(220, 220, 220, 0.26);
  transition: background-color $delay;

  .flex-holder {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 87px;

    @media (max-width: $md) {
      min-height: 67px;
    }
  }

  .logo {
    width: 100%;
    max-width: 153px;

    &.dark-logo {
      display: none;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .burger-btn {
    display: none;
    width: 33px;
    height: 33px;
    margin-left: auto;

    @media (max-width: $md) {
      display: block;
    }

    svg {
      width: 100%;
    }
  }

  .menu-holder {
    margin-left: auto;
    transform: translateX(15px);
    font-size: 16px;
    font-weight: 500;

    @media (max-width: $lg) {
      transform: none;
    }

    @media (max-width: $md) {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;

        a {
          font-weight: 500;
        }
      }
    }

    & > ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & > li {
        margin-right: 47px;
        padding: 18px 0;

        @media (max-width: $lg) {
          margin-right: 30px;
        }

        &:last-of-type {
          margin-right: 0;
        }

        & > a {
          color: $white;

          &:hover {
            color: $theme-red;
          }
        }

        &.menu-item-has-children {
          position: relative;
          padding-right: 17px;
          cursor: pointer;

          &:hover {
            .sub-menu {
              display: block;
            }

            & > a {
              color: $theme-red;
            }
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 0;
            width: 8px;
            height: 5px;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1.33398L5 5.33398L1 1.33398' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }

        &.h-button {
          & > a {
            padding: 11px 21px;
            border: 1px solid rgba($white, 0.3);
            border-radius: 10px;
            transition: border-color $delay, background-color $delay;

            &:hover {
              background-color: $theme-red;
              color: $white;
              border-color: $theme-red;
            }
          }
        }
      }
    }

    .sub-menu {
      display: none;
      padding: 10px;
      position: absolute;
      z-index: 2;
      width: 100%;
      min-width: 200px;
      top: 55px;
      left: 0;
      background-color: $white;

      li {
        margin-bottom: 10px;

        &:last-of-type {
          margin: 0;
        }

        a {
          color: $theme-dark-gray;

          &:hover {
            color: $theme-red;
          }
        }
      }
    }
  }

  &.white-bg {
    background-color: $white;

    .logo:not(.dark-logo) {
      display: none;
    }

    .dark-logo {
      display: block;
    }

    .menu-holder {
      & > ul {
        & > li {
          &.menu-item-has-children {
            &::after {
              background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1.33398L5 5.33398L1 1.33398' stroke='%23323232' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
          }

          &.h-button {
            & > a {
              border: 1px solid rgba(#999999, 0.3);

              &:hover {
                background-color: $theme-red;

                &:hover {
                  color: $white;
                }
              }
            }
          }

          & > a {
            color: #323232;

            &:hover {
              color: $theme-red;
            }
          }
        }
      }
    }

    .burger-btn {
      svg path {
        fill: #333333;
      }
    }
  }

  .mobile-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    padding: 15px 0 20px;
    transform: translateY(-100%);
    transition: transform $delay;

    &.visible {
      transform: none;
    }

    @media (max-width: $md) {
      display: block;
    }

    .top-holder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 56px;

      .logotyp {
        width: 100%;

        img {
          display: block;
          max-width: 153px;
          width: 100%;
        }
      }

      .close-menu {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 100%;
        }
      }
    }

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;

      li {
        list-style-type: none;

        a {
          color: #323232;

          &:hover {
            color: $theme-red;
          }
        }
      }
    }

    ul.menu {
      & > li {
        margin-bottom: 11px;

        &:last-of-type {
          margin-bottom: 0;
        }

        & > a {
          display: block;
          background: #F6F6F6;
          border-radius: 10px;
          width: 100%;
          padding-top: 18px;
          padding-bottom: 18px;
        }

        &.menu-item-has-children {
          & > a {
            position: relative;
            display: block;
            padding-right: 17px;

            &::after {
              content: '';
              position: absolute;
              margin-left: 7px;
              top: 27px;
              width: 10px;
              height: 7px;

              background-repeat: no-repeat;
              background-size: cover;
              background-image: url("data:image/svg+xml,%0A%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1.33398L5 5.33398L1 1.33398' stroke='%23323232' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");          }
          }

          .sub-menu {
            padding: 15px 0 3px;
            display: none;

            li {
              margin-bottom: 15px;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }

        &.h-button {
          & > a {
            border: 1px solid rgba(50, 50, 50, 0.24);
            background-color: $white;
            transition: border-color $delay, background-color $delay;

            &:hover {
              background-color: $theme-red;
              color: $white;
              border-color: $theme-red;
            }
          }
        }
      }
    }
  }
}